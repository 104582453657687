import React, { useEffect } from 'react'

const PODCAST_URL = 'https://lp.kode.ru/landings/podcast'

const Podcast: React.FC = () => {
  useEffect(() => {
    window.location.replace(PODCAST_URL)
  }, [])
  return (
    null
  )
}

export default Podcast
